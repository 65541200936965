
const url = window.location.hash;

export let anchorLink = (e)=>{
	 if(url){
	const target = url.slice(1);
	const link = document.getElementById(target);
	const header =  document.getElementById('js-headerWrap');
	let margin = (link.classList.contains('js-serviceAnchor'))? header.clientHeight : 0;
	const duration = 500;
		e.preventDefault();
		let rectTop = link.getBoundingClientRect().top; //要素の位置
		let offsetTop = window.pageYOffset; // 現在のスクロール距離
		let linktop = rectTop + offsetTop - margin; //link先の距離
		window.scrollTo(0, linktop);
	}
};

