const btns : any = document.querySelectorAll('.js-switch');
const targets: NodeListOf<Element> =document.querySelectorAll('.js-swTarget');
let clickedBtn :number ;

export let tableBtnEvent = ()=>{
	if(document.querySelector('.js-switch')){
		for (const btn of btns) {
			btn.addEventListener('click',()=>{
				clickedBtn = Array.prototype.indexOf.call(btns,btn);
				activeEvent(clickedBtn);
			});
		}
	}}

		function activeEvent(clickedBtn){
			btns.forEach((btn,i) => {
				if(i === clickedBtn) {
					btn.classList.add('is-active');
					targets[i].classList.add('is-active')
				}else{
					btn.classList.remove('is-active');
					targets[i].classList.remove('is-active');
				}
			});
	}




