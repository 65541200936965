import { hSize } from "./heroHeight";
const target: HTMLElement = document.getElementById("js-topContact");
const targetTop: number = (target)?target.getBoundingClientRect().top:0;
const hideTarget: HTMLElement = document.getElementById("js-topContactHide");
const hidePoint: number = (hideTarget) ? hideTarget.getBoundingClientRect().top :0 ;
const firstScrollTop: number = window.pageYOffset;
let scrollTop: number = window.pageYOffset;

export let topContactEvent =  () => {
	scrollTop = window.pageYOffset;

  if (document.getElementById("js-topContact")) {
    if (hSize < targetTop + scrollTop) {
      target.classList.add("is-scroll");
      if (hidePoint + firstScrollTop < targetTop + scrollTop) {
        target.classList.add("is-hide");
      } else {
        target.classList.remove("is-hide");
      }
    } else if (hSize > targetTop + scrollTop) {
      target.classList.remove("is-scroll");
    }
  }
};
