import { Hum } from './hum';
import { HumItem } from './humItem';
import { NavItem } from './navItem';
import { Body } from './body';
// import { breakPointLG } from "../globalVar";
// import { firstWindowWidth } from "../globalVar";

let humInstance;
let humItemInstance = [];
let navInstance = [];
let navBgInstance;
let bodyInstance;

const header = document.getElementById('header');
const hum = document.getElementById('js-hum');
const humItems = hum.querySelectorAll('.js-humItem');
const navBg = document.getElementById('js-nav');
const navItems = navBg.querySelectorAll('.js-navItem');
const navItemsLength: number = navItems.length;
const body = document.querySelector('body');

export function closeEvent() {
  bodyInstance.fixedOff();
	for (const el of humItemInstance) {
		el.close();
	}
	navInstance.forEach((nav,i) => {
		let time = i * 20;
    let target = function () {
      navInstance[navItemsLength - i - 1].close();
    };
    window.setTimeout(target, time);
	});
  setTimeout(() => {
    navBgInstance.close();
    humInstance.close();
  }, (navItemsLength - 1) * 20 + 10);
}

function openEvent() {
  bodyInstance.fixedOn();
  humInstance.open();
  navBgInstance.open();
	for (const el of humItemInstance) {
		el.open()
	}
	navInstance.forEach((nav,i) => {
		let time = (i + 1) * 100;
    let target =  ()=> {
      nav.open();
    };
    window.setTimeout(target, time);
	});
}

function clickEvent(type) {
  if (type === 'close') {
    closeEvent();
  } else if (type === 'open') {
    openEvent();
  }
}

export function headerInit() {
  humInstance = new Hum({
    selector: hum,
    onClick: (type) => {
      clickEvent(type);
    },
  });
	humItems.forEach((humItem,i) => {
		humItemInstance[i] = new HumItem({
			selector:humItem,
		})
	});

navItems.forEach((navItem,i)=>{
	navInstance[i] = new NavItem({
		selector : navItem,
		onClick: (type) => {
			clickEvent(type);
		},
	});
	navInstance[i].init();
})

  navBgInstance = new NavItem({
    selector: navBg,
  });

  bodyInstance = new Body({
    selector: body,
  });
  humInstance.init();
}