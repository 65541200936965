import { breakPointLG } from './../globalVar';
export let hSize: number;
 export let heroHeight = ()=> {
	if(document.getElementById("js-getHeight")){
		const getHeight: HTMLElement = document.getElementById('js-getHeight');
		const hero: HTMLElement = document.getElementById('js-topTarget');
		hSize  = getHeight.clientHeight;
		hSize = getHeight.clientHeight;
		if (window.innerWidth > breakPointLG) {
			(hSize < 1080) ? hero.style.height = hSize + 80 + 'px' : hero.style.height = 1160 + 'px';
		} else {
			hero.style.height = '';
		}
	}
};

