import { breakPointLG } from '../globalVar';
export class Body {
  selector: HTMLElement;
  scrollPosition: number;
  constructor(_param) {
    this.selector = _param.selector;
    this.scrollPosition = 0;
  }

  fixedOn() {
    if (window.innerWidth < breakPointLG) {
      this.scrollPosition = window.pageYOffset;
      this.selector.style.position = 'fixed';
      this.selector.style.top = `-${this.scrollPosition}px`;
    }
  }
  fixedOff() {
		if(this.selector.style.position === 'fixed' ){
			this.selector.style.removeProperty('position');
      this.selector.style.removeProperty('top');
      window.scrollTo(0, this.scrollPosition);
		}
  }
}

